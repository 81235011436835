.App {
  max-width: 1900px;
  min-width: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
} 


a {
  text-decoration: none;
  cursor: pointer;
}
